.bear-react-carousel__root{
    width: 100%;
}

.bear-react-carousel__test-number{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    font-size: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    pointer-events: none;
    overflow: auto;
}


.bear-react-carousel__window-size{
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: rgba(0, 0, 0, .5);
    font-size: 12px;
    padding: 5px 10px;
}


.bear-react-carousel__pagination-group{
    position: absolute;
    bottom: 20px;
    text-align: center;
    width: 100%;
    z-index: 10;
}

.bear-react-carousel__pagination-button{
    width: .5rem;
    height: .5rem;
    border-radius: 7px;
    background-color: #bdbdbd;
    display: inline-block;
    margin: 0 6px;
    cursor: pointer;
    transition: width 0.3s, background-color 0.3s;
    box-shadow: 3px 3px 9px 0 rgb(189 189 189 / 12%);
    opacity: 0.6;
}

.bear-react-carousel__pagination-button[data-active]{
    background-color: var(--primary-color, #00a3e0);
}


.bear-react-carousel__pagination-content{}



.bear-react-carousel__clone-icon-group{
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 3;
    display:flex;
    flex-direction: row;
    font-size: 12px;
    color: #fff;
}

.bear-react-carousel__clone-icon{
    width: 20px;
    height: 20px;
    background: transparent no-repeat center center;
    background-size: 100%;
    opacity: .5;
}

.bear-react-carousel__nav-prev-button, .bear-react-carousel__nav-next-button {
    border: none;
    outline: none;
    color: #fff;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, .5);
    border-radius: 99em;
    overflow: hidden;
    opacity: .6;

    position: absolute;
    z-index: 11;
    top: 0;
    bottom: 0;
    margin: auto 0;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;

}

.bear-react-carousel__nav-group > .bear-react-carousel__nav-prev-button{
    left: 10px;
}
.bear-react-carousel__nav-group > .bear-react-carousel__nav-next-button{
    right: 10px ;
    transform: rotate(180deg);
}


.bear-react-carousel__nav-icon{
    background: no-repeat center;
    background-size: 30px;
    height: 20px;
    width: 20px;
}




.bear-react-carousel__slide-item{
    transition-property: transform;
    position: relative;
    overflow: hidden;
}

img.bear-react-carousel__slide-item__img{
    max-width: 100%;
    width: auto;
}

div.bear-react-carousel__slide-item__div{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}




.bear-react-carousel__container{
    position: relative;
    display: flex;
    flex-direction: row;
    visibility: hidden; /* 避免瞬間移動, 故移動完才改為顯示 */
    height: inherit;
    transition-timing-function: ease;
}
.bear-react-carousel__container.bear-react-carousel__container-init{
    visibility: initial;
}




.bear-react-carousel__content{
    position: absolute; /* 防止子層container超出顯示 */
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    overflow: hidden;
}


.bear-react-carousel__root{
    position: relative;
    height: inherit;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}


.bear-react-carousel__root[data-gpu-render=true] .bear-react-carousel__container{
    will-change: transform;
}

.bear-react-carousel__root[data-mouse-move="true"] .bear-react-carousel__container{
    cursor: pointer;
}

.bear-react-carousel__root[data-debug="true"] .bear-react-carousel__test-number{
    background-color: rgba(0, 0, 0, .5);
}

